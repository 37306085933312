/** 
  $orange: #f55f33
  $blue: #4F5BD5
  $dark-blue: #27405C
  $green: #42D5B0
  $yellow: #F9BA0F
  $pink: #FB1FFF
  $brown: #6B520D
*/

/**
* BOOTSTRAP EXTEND
*/
.font-blue-color {
  color: #001bd9;
}

.btn-tagether,
.btn-outline-tagether:hover,
.table-tagether th,
.table-tagether td {
  background-color: #001bd9;
  color: white;
}

.btn-outline-tagether,
.btn-tagether:hover {
  border-color: #001bd9;
  color: #001bd9;
}

.btn-tagether-pink,
.btn-outline-tagether-pink:hover {
  background-color: #fb1fff;
  color: white;
}

.btn-outline-tagether-pink,
.btn-tagether-pink:hover {
  background-color: #ffffff;
  border-color: #fb1fff;
  color: #fb1fff;
}

.btn-blue,
.btn-outline-blue:hover,
.table-blue th,
.table-blue td,
.pagination-blue .active .page-link {
  background-color: #4f5bd5;
  color: white;
}

.btn-outline-blue,
.btn-blue:hover,
.pagination-blue .page-link {
  border-color: #4f5bd5;
  color: #4f5bd5;
}

.btn-dark-blue,
.btn-outline-dark-blue:hover,
.table-dark-blue th,
.table-dark-blue td,
.pagination-dark-blue .active .page-link {
  background-color: #27405c;
  color: white;
  text-align: center;
  font-size: 12px;
  vertical-align: center;
}

.btn-outline-dark-blue,
.btn-dark-blue:hover,
.pagination-dark-blue .page-link {
  border-color: #27405c;
  color: #27405c;
}

.btn-action:hover {
  background-color: #edf5ff !important;
  border-color: #edf5ff;
  box-shadow: 0 3px 10px #e7e8ee8f;
  color: #001bd9;
  cursor: pointer;
}

.bg-tagether-green {
  background-color: #42d5b0;
}

.bg-tagether-light-green {
  background-color: rgba(66, 213, 176, 0.3);
}

.bg-tagether-blue {
  background-color: #4f5bd5;
}

.bg-tagether-light-blue {
  background-color: rgba(237, 245, 255, 1);
}

.bg-tagether-pink {
  background-color: #fb1fff;
}

.bg-tagether-light-pink {
  background-color: rgba(251, 31, 255, 0.3);
}

.bg-tagether-yellow {
  background-color: #f9ba0f;
}

.bg-tagether-brown {
  background-color: #6b520d;
}

.border-tagether-green {
  border-color: #42d5b0;
}

.border-tagether-blue {
  border-color: #4f5bd5;
}

.border-tagether-pink {
  border-color: #fb1fff;
}

.border-tagether-yellow {
  border-color: #f9ba0f;
}

.text-tagether-yellow {
  color: #f9ba0f;
}

.nav-pills .nav-link {
  font-weight: 600;
}

.nav-pills .nav-link.active {
  background-color: rgba(222, 235, 253);
  color: #001bd9 !important;
  font-weight: 700;
}

.nav-pills .nav-link:hover {
  background-color: #e7e7e7;
  color: rgba(0, 0, 0) !important;
  font-weight: 700;
}

.border-tagether {
  border-color: #f55f33 !important;
}

.dropdown-toggle::after {
  display: none;
}

.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-image--instagram {
  background-image: url("./assets/images/instagram.svg");
}

/**
* CUSTOM
*/

.header-tabbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

.hero-webapp-layout {
  display: flex;
  flex-direction: row;
}

.navigation-section {
  padding: 1rem;
  background-color: #f2f7f8;
  position: relative;
}

.logout-button {
  background-color: #001bd9;
  font-weight: 700;
  width: 100%;
}

.content-section {
  padding: 3rem 2rem;
  width: 85%;
  background-color: #fefefe;
  height: auto !important;
  min-height: 100vh;
}

@media screen and (max-width: 425px) {
  .hero-webapp-layout {
    display: flex;
    flex-direction: column;
  }

  .navigation-section {
    width: 50%;
  }

  .logout-button {
    width: 50%;
  }

  .content-section {
    padding: 2rem;
    width: 100%;
  }
}

.card-count-container {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2rem;
}

.card-tips-container {
  font-size: 16px;
  font-weight: 500;
  width: 35%;
}

.card-count-box {
  padding: 1rem;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.5px;
  border-color: darkgray;
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.card-count-box-space {
  margin-left: 2rem;
}

.card-count-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  height: 30px;
}

.social-detail-card {
  position: absolute;
  background-color: #f2f7f8;
  border-radius: 0.375rem;
  padding: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 10;
}

@media screen and (max-width: 425px) {
  .card-count-container {
    font-size: 12px;
    font-weight: bold;
  }

  .card-count-box {
    padding: 1rem;
  }
}

.card-body {
  padding: 1rem;
}

.config-reward-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.empty-reward-box-text {
  color: #fefefe;
  display: inline;
  text-align: center;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 15px;
  background-color: #f9ba0f;
}

.add-form-reward {
  background-color: #edf5ff;
  padding-top: 4rem;
  border-radius: 10px;
  padding-bottom: 4rem;
}

.instagram-username-social-card {
  width: fit-content;
  padding: 2px 8px;
  border-radius: 20px;
  background-color: black;
  color: #f7f7f7;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.instagram-username-social-card-text {
  text-align: end;
  justify-content: center;
  margin-bottom: 0;
  font-weight: bold;
}

.rounded-top-start {
  border-top-left-radius: 10px;
}

.rounded-top-end {
  border-top-right-radius: 10px;
}

.content-table {
  font-size: 12px;
}

.content-table-media {
  font-size: 10px;
}

.tutorial-temporary-message-box {
  position: relative;
  width: 50%;
  margin-top: 10%;
  margin-left: 25%;
  padding: 2rem;
  border-radius: 6px;
  background-color: #fefefe;
  display: flex;
  flex-direction: row;
}

.tutorial-temporary-message-title {
  width: fit-content;
  padding: 1.4rem;
  border-radius: 45px;
  color: #f7f7f7;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 2rem;
}

.logo {
  position: absolute;
  top: -48px;
}

@media (max-width: 767px) {
  .logo {
    position: initial;
  }
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #1d2f43;
  z-index: -1;
}

.background--half {
  width: 66.66%;
}

@media (max-width: 991px) {
  .background--half {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .background {
    display: none;
  }
}

.gradient {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.gradient--bottom-left {
  bottom: 0;
  left: 0;
  background: url("./assets/images/layout/gradient-1.png") no-repeat bottom -150px left -350px;
  background-size: 593px;
}

.gradient--top-right {
  top: 0;
  right: 0;
  background: url("./assets/images/layout/gradient-2.png") no-repeat top -200px right -50px;
  background-size: 626px;
}

@media (max-width: 991px) {
  .gradient {
    display: none;
  }
}

.nav--sticky {
  position: sticky;
  top: 81px;
  height: calc(100vh - 105px);
}

.nav-logout {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.reward-icon {
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: center;
}

.reward-icon--large {
  background-size: 40%;
  background-position: center top 10%;
  border-color: #edf5ff;
  padding-bottom: 1rem;
}

.reward-icon--large:hover {
  box-shadow: 0 2px 4px rgba(45, 30, 252, 0.6);
  color: #27405c;
  border-color: #4f5bd5;
  border-width: 2px;
  border-style: solid;
}

.reward-icon-code {
  background-image: url("./assets/images/icons/icon-code-1.png");
}

.reward-icon-percent {
  background-image: url("./assets/images/icons/icon-percent.png");
}

.reward-icon-voucher {
  background-image: url("./assets/images/icons/icon-voucher.png");
}

.reward-icon-other {
  background-image: url("./assets/images/icons/icon-other.png");
}
